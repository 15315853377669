import React from "react"
// import { styled, } from "styled-components"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <div className="container mx-auto">
      {/* <section id="branding" className="mb-16 px-8">
        <h3 className="mb-4">Branding</h3>
      </section> */}

      <section id="colors" className="mb-16 px-8">
        <h3 className="mb-4">Colors</h3>

        <div className="p-8 text-gray-700 text-center bg-white rounded-lg">
          <ul className="grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
            <li>
              <div className="grid grid-cols-1 grid-rows-1 pd-2 mb-2 shadow-2xl rounded overflow-hidden">
                <div className="p-4 text-red-900 bg-red-100">red-100</div>
                <div className="p-4 text-red-900 bg-red-200">red-200</div>
                <div className="p-4 text-red-900 bg-red-300">red-300</div>
                <div className="p-4 text-red-900 bg-red-400">red-400</div>
                <div className="p-4 text-red-100 bg-red-500">red-500</div>
                <div className="p-4 text-red-100 bg-red-600">red-600</div>
                <div className="p-4 text-red-100 bg-red-700">red-700</div>
                <div className="p-4 text-red-100 bg-red-800">red-800</div>
                <div className="p-4 text-red-100 bg-red-900">red-900</div>
              </div>
              <p>
                Red <span className="text-gray-500">#EC4240</span>
              </p>
            </li>
            <li>
              <div className="grid grid-cols-1 grid-rows-1 pd-2 mb-2 shadow-2xl rounded overflow-hidden">
                <div className="p-4 text-blue-900 bg-blue-100">blue-100</div>
                <div className="p-4 text-blue-900 bg-blue-200">blue-200</div>
                <div className="p-4 text-blue-900 bg-blue-300">blue-300</div>
                <div className="p-4 text-blue-900 bg-blue-400">blue-400</div>
                <div className="p-4 text-blue-100 bg-blue-500">blue-500</div>
                <div className="p-4 text-blue-100 bg-blue-600">blue-600</div>
                <div className="p-4 text-blue-100 bg-blue-700">blue-700</div>
                <div className="p-4 text-blue-100 bg-blue-800">blue-800</div>
                <div className="p-4 text-blue-100 bg-blue-900">blue-900</div>
              </div>
              <p>
                Blue <span className="text-gray-500">#1098f7</span>
              </p>
            </li>
            <li>
              <div className="grid grid-cols-1 grid-rows-1 pd-2 mb-2 shadow-2xl rounded overflow-hidden">
                <div className="p-4 text-portica-900 bg-portica-100">
                  portica-100
                </div>
                <div className="p-4 text-portica-900 bg-portica-200">
                  portica-200
                </div>
                <div className="p-4 text-portica-900 bg-portica-300">
                  portica-300
                </div>
                <div className="p-4 text-portica-900 bg-portica-400">
                  portica-400
                </div>
                <div className="p-4 text-portica-100 bg-portica-500">
                  portica-500
                </div>
                <div className="p-4 text-portica-100 bg-portica-600">
                  portica-600
                </div>
                <div className="p-4 text-portica-100 bg-portica-700">
                  portica-700
                </div>
                <div className="p-4 text-portica-100 bg-portica-800">
                  portica-800
                </div>
                <div className="p-4 text-portica-100 bg-portica-900">
                  portica-900
                </div>
              </div>
              <p>
                Portica <span className="text-gray-500">#ffed66</span>
              </p>
            </li>
            <li>
              <div className="grid grid-cols-1 grid-rows-1 pd-2 mb-2 shadow-2xl rounded overflow-hidden">
                <div className="p-4 text-alto-900 bg-alto-100">alto-100</div>
                <div className="p-4 text-alto-900 bg-alto-200">alto-200</div>
                <div className="p-4 text-alto-900 bg-alto-300">alto-300</div>
                <div className="p-4 text-alto-900 bg-alto-400">alto-400</div>
                <div className="p-4 text-alto-100 bg-alto-500">alto-500</div>
                <div className="p-4 text-alto-100 bg-alto-600">alto-600</div>
                <div className="p-4 text-alto-100 bg-alto-700">alto-700</div>
                <div className="p-4 text-alto-100 bg-alto-800">alto-800</div>
                <div className="p-4 text-alto-100 bg-alto-900">alto-900</div>
              </div>
              <p>
                alto <span className="text-gray-500">#d8d8d8</span>
              </p>
            </li>
            <li>
              <div className="grid grid-cols-1 grid-rows-1 pd-2 mb-2 shadow-2xl rounded overflow-hidden">
                <div className="p-4 text-cove-900 bg-cove-100">cove-100</div>
                <div className="p-4 text-cove-900 bg-cove-200">cove-200</div>
                <div className="p-4 text-cove-900 bg-cove-300">cove-300</div>
                <div className="p-4 text-cove-900 bg-cove-400">cove-400</div>
                <div className="p-4 text-cove-100 bg-cove-500">cove-500</div>
                <div className="p-4 text-cove-100 bg-cove-600">cove-600</div>
                <div className="p-4 text-cove-100 bg-cove-700">cove-700</div>
                <div className="p-4 text-cove-100 bg-cove-800">cove-800</div>
                <div className="p-4 text-cove-100 bg-cove-900">cove-900</div>
              </div>
              <p>
                Cove <span className="text-gray-500">#0c0a3e</span>
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section id="typography" className="mb-16 px-8">
        <h3 className="mb-4">Typography</h3>

        <div className="p-8 text-gray-700 bg-white rounded-lg">
          <h1>Headline 1</h1>
          <h2>Headline 2</h2>
          <h3>Headline 3</h3>
          <h4>Headline 4</h4>
          <h5>Headline 5</h5>
          <h6>Headline 6</h6>

          <br />

          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias nihil
            totam sunt cum autem ea, quasi incidunt officiis quod aliquid
            molestiae neque eaque! Accusamus iusto ducimus labore voluptates
            quaerat aspernatur?
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
            dolore, possimus, a ullam minima explicabo iusto, repellat quia
            autem ab eius sapiente aut! Libero corrupti voluptas expedita ex
            consequatur quam? <a href="#typography">Perspiciatis architecto</a>{" "}
            tenetur veritatis adipisci, praesentium doloribus quos fugit et id
            facere, nostrum minima placeat aut cum error suscipit ipsam
            recusandae nam ipsa impedit hic. Fugit necessitatibus expedita
            repellendus laboriosam? Vitae consectetur quia aliquam facere
            voluptas ipsam nemo, placeat expedita est blanditiis, esse
            perferendis aut molestias, sunt ipsa suscipit nisi maxime a?
            Sapiente temporibus odio aliquam ullam possimus tempore vero!
          </p>
        </div>
      </section>

      {/* <section id="buttons" className="mb-16 px-8">
        <h3 className="mb-4">Buttons</h3>
      </section> */}

      <section></section>
    </div>
  </Layout>
)
